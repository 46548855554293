
import {defineComponent} from 'vue';
import theHeader from "@/components/the-header.vue";
import theFooter from "@/components/the-footer.vue";

export default defineComponent({
  name: 'App',
  components: {
    theHeader,
    theFooter,
  },
  setup() {




    return {

    };
  }
});
