

import {defineComponent} from "vue";
import {FireTwoTone, SmileTwoTone} from '@ant-design/icons-vue';
import {message} from "ant-design-vue";


export default defineComponent({
  name: 'right-chat-item',
  props: ['content'],
  components: {
    FireTwoTone,
    SmileTwoTone,
  },
  setup : function (props : any) {

    const copyToClipboard = () => {
      navigator.clipboard.writeText(props.content).then(() => {
        message.success('内容已复制到剪贴板');
      }, () => {
        console.error('复制失败');
      });
    }

  return {
    copyToClipboard,
  }

}
});

