
import {defineComponent} from 'vue';

// import axios from 'axios';


export default defineComponent({
  components: {
  },
  name: 'Home',
  setup() {


    // onMounted(() => {
    //   console.log()
    // });

    return {};
  },
});
