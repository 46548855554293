
import {computed, defineComponent, onMounted, ref} from 'vue';
import axios from "axios";
import {message, notification} from "ant-design-vue";
import {BellTwoTone, DownOutlined, ShareAltOutlined, SyncOutlined, UserOutlined} from "@ant-design/icons-vue";
import store from "@/store";
import {Tool} from "@/utils/tool";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'the-header',
  components: {
    SyncOutlined,
    UserOutlined,
    DownOutlined,
    ShareAltOutlined,
    BellTwoTone,
  },
  setup: function () {
	  const router = useRouter()

    //-------------登录表单--------------
    const loginModalVisible = ref(false);
    const loginModalLoading = ref(false);
    const userInModal = ref();
    userInModal.value = {};

    /**
     * 跳转到 GitHub 登录
     */
    const loginByGitHub = () => {
      window.open("http://study.playoffer.cn:8111/user/render");
    }

    /**
     * 登录按钮点击, 弹出登录将框
     */
    const loginClick = () => {
      loginModalVisible.value = true;
    }

    /**
     * 登录表单提交
     */
    const loginModalOk = () => {
      console.log(userInModal.value);
      if (Tool.isEmpty(userInModal.value.email) || Tool.isEmpty(userInModal.value.password)) {
        message.warn("邮箱或密码不能为空呦~");
        return;
      }
      axios.post("/user/loginByEmail", userInModal.value).then((response) => {
        if (response.data.success) {    // 登录成功
	        loginModalVisible.value = false;
	        checkLoginCert();
	        router.go(0);
        } else {
          message.error(response.data.message);
        }
      })
    }

    //-------------注册表单--------------
    const registerModalVisible = ref(false);
    const registerModalLoading = ref(false);
    const verifyBtnDisable = ref(false);

    /**
     * 注册按钮点击
     */
    const registerClick = () => {
      registerModalVisible.value = true;
    }

    /**
     * 注册表单提交
     */
    const registerModalOk = () => {
      axios.post("/user/register", userInModal.value).then((response2) => {
        if (response2.data.success) {    // 注册成功
          message.success(response2.data.message + ", 自动跳转到登录页面", 5);
          registerModalVisible.value = false;
          loginModalVisible.value = true;
        } else {
          message.error(response2.data.message);
        }
      })
    }

    /**
     * 发送注册时的邮箱验证码
     */
    const sendVerifyCode = () => {
      if (Tool.isEmpty(userInModal.value.email)) {
        message.error("邮箱不能为空呦");
        return;
      }
      verifyBtnDisable.value = true;
      axios.post("/user/sendActiveEmail/" + userInModal.value.email).then((response) => {

        if (response.data.success) {
          message.success("验证码发送成功", 5);
          verifyBtnDisable.value = false;
        } else {
          verifyBtnDisable.value = false;
          message.error(response.data.message);
        }
      })
    }

    /**
     * 发送忘记密码时的邮箱验证码
     */
    const sendVerifyCodeForget = () => {
      if (Tool.isEmpty(userInModal.value.email)) {
        message.error("邮箱不能为空呦");
        return;
      }
      verifyBtnDisable.value = true;
      axios.post("/user/sendActiveEmailForget/" + userInModal.value.email).then((response) => {

        if (response.data.success) {
          message.success("验证码发送成功", 5);
          verifyBtnDisable.value = false;
        } else {
          verifyBtnDisable.value = false;
          message.error(response.data.message);
        }
      })
    }

    const getInviteCode = () => {
      window.open("https://www.playoffer.cn/1619.html");
    }

    const isRegister = () => {
      // 检测链接中是否含 register 参数
      const urlParams = new URLSearchParams(window.location.search);
      const registerParam = urlParams.get('register');
      // console.log(registerParam);

      // 有 register 参数
      if (Tool.isNotEmpty(registerParam)) {
        // console.log("aa");
        userInModal.value.inviteCode = registerParam;
        registerModalVisible.value = true;
        // // 检测是否登录
        // if (Tool.isNotEmpty(userInfo.value)) {
        //   message.info("已经登陆了呦~");
        //   return;
        // }
        return;
      }
    }

    //-------------忘记密码--------------
    const forgetModalVisible = ref(false);
    const forgetModalLoading = ref();
    const repeatNewPassword = ref();


    const forgetPasswordHandle = () => {
      forgetModalVisible.value = true;
    }

    const forgetModalOk = () => {
      if (userInModal.value.password != repeatNewPassword.value) {
        message.warn("两次密码不一样呦");
        return;
      }

      axios.post("/user/forget", userInModal.value).then((response2) => {
        if (response2.data.success) {
          message.success(response2.data.message + "更改密码成功, 自动跳转到登录页面", 5);
          forgetModalVisible.value = false;
          loginModalVisible.value = true;
        } else {
          message.error(response2.data.message);
        }
      })
    }

    //-------------登录/注册标识--------------
    const ifLoginIn = ref(false);
    const userInfo = computed(() => {
      return store.state.userInfo;
    })
    const userInfoRefresh = ref(false);
    const descriptionColumn = 1;

    const userInfoClick = () => {
      userInfoRefresh.value = true;
      axios.defaults.withCredentials = true;
      axios.post("/user/loginByID/" + userInfo.value.id).then((response) => {
        if (response.data.success) {   // 成功则加载用户信息
          store.commit("setUserInfo", response.data.content);
        } else {
          message.error(response.data.message);
        }
        userInfoRefresh.value = false;
      })
    }

    /**
     * 点击右上角的账户详情
     */
    const dropdownClickHandle = () => {
      axios.defaults.withCredentials = true;
      axios.post("/user/loginByID/" + userInfo.value.id).then((response) => {
        if (response.data.success) {   // 成功则加载用户信息
          store.commit("setUserInfo", response.data.content);
        }
      })
    };

    /**
     * 每日签到
     */
    const signInPerDayClick = () => {
      axios.post("/user/signInPerDay", userInfo.value.id).then((response) => {
        let data = response.data;
        if (response.data.success) {
          message.success(data.message);
        } else {
          message.info(data.message);
        }
      })
    }

    const getUserType = (type: any) => {
      if (type === 1) {
        return "普通用户";
      } else if (type === 2) {
        return "会员 剩余: " + userInfo.value.remainDays + "天";
      } else if (type === 3) {
        return "超级会员";
      } else {
        return "类型错误";
      }
    }

    /**
     * 自动登录
     */
    const autoLogin = (userID: string) => {
      axios.defaults.withCredentials = true;
      axios.post("/user/loginByID/" + userID).then((response) => {
	      // console.log("尝试自动登录");
	      // console.log(response);

        if (response.data.success) {   // 成功则加载用户信息
          store.commit("setUserInfo", response.data.content);
          // wsLogin();
          ifLoginIn.value = true;
        }  // 失败无提示
      })
    }

    /**
     * 验证登录凭证
     */
    const checkLoginCert = () => {
      axios.defaults.withCredentials = true;
      axios.post("/user/checkLoginCert").then((response) => {       // 验证登录凭证是否有效
        // console.log("验证登录凭证")
        // console.log(response);

        if (response.data.success) {                                    // 若凭证有效, 检测本地存储 userInfo 是否为空,
          if (Tool.isEmpty(userInfo.value)) {                           // 若 userInfo 为空, 则为初次登录, 尝试自动登录
            // console.log("凭证有效, userInfo 为空")
            autoLogin(response.data.content);
          } else {                                                      // 若 userInfo 不为空, 则正处于登录态
	          // console.log("已经是登录态了");
	          // wsLogin();
            ifLoginIn.value = true;
          }

        } else {                                                        // 凭证失效, 或者 userInfo 不为空, do nothing
          store.commit("setUserInfo", {});
        }
      })
    }

    //-------------退出登录--------------

    /**
     * 点击退出登录
     */
    const logoutClick = () => {
      axios.defaults.withCredentials = true;
      axios.post("/user/logoutByAccount").then((response) => {
        if (response.data.success) {
          // loginModalVisible.value = true;
          store.commit("setUserInfo", {});
          window.location.href = process.env.VUE_APP_WEB;
        } else {
          message.error(response.data.message);
        }
      })
    }

    //-------------WebSocket--------------

    let websocket: any;
    let token: any;

    const onOpen = () => {
      // console.log('WebSocket连接成功，状态码：', websocket.readyState)
    };

    const onMessage = (event: any) => {
      // console.log('WebSocket收到消息：', event.data);
      notification['info']({
        message: '收到消息',
        description: event.data,
      });
    };

    const onError = () => {
      // console.log('WebSocket连接错误，状态码：', websocket.readyState)
    };

    const onClose = () => {
      // console.log('WebSocket连接关闭，状态码：', websocket.readyState)
    };

    const initWebSocket = () => {
      // 连接成功
      websocket.onopen = onOpen;
      // 收到消息的回调
      websocket.onmessage = onMessage;
      // 连接错误
      websocket.onerror = onError;
      // 连接关闭的回调
      websocket.onclose = onClose;
    };

    const wsLogin = () => {
      // WebSocket
      if ('WebSocket' in window) {
        token = Tool.uuid(10);
        // 连接地址：ws://127.0.0.1:8880/ws/xxx
        websocket = new WebSocket(process.env.VUE_APP_WS_SERVER + '/ws/' + token);
        initWebSocket()

        // 关闭
        // websocket.close();
      }
    };

    onMounted(() => {
      checkLoginCert();
      isRegister();
    });


    return {
      loginModalVisible,
      loginModalLoading,
      ifLoginIn,

      registerModalVisible,
      registerModalLoading,
      // verifyCode,

      forgetModalVisible,
      forgetModalLoading,
      repeatNewPassword,

      userInModal,
      userInfo,
      verifyBtnDisable,
      userInfoRefresh,
      descriptionColumn,

      loginByGitHub,
      loginModalOk,
      loginClick,
      registerClick,
      registerModalOk,
      sendVerifyCode,
      forgetPasswordHandle,
      forgetModalOk,
      dropdownClickHandle,
      userInfoClick,
      getInviteCode,
      getUserType,
      signInPerDayClick,
      logoutClick,
      sendVerifyCodeForget,
    };
  }
});
