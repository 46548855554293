import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc3ca0e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_smile_two_tone = _resolveComponent("smile-two-tone")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_smile_two_tone, { style: {"font-size":"35px","padding-top":"5px"} }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyToClipboard && _ctx.copyToClipboard(...args))),
        class: "text"
      }, _toDisplayString(_ctx.content), 1)
    ])
  ]))
}