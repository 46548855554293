import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_footer = _resolveComponent("the-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "layout" }, {
    default: _withCtx(() => [
      (_ctx.$route.meta.exclude !== true)
        ? (_openBlock(), _createBlock(_component_the_header, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view),
      (_ctx.$route.meta.exclude !== true)
        ? (_openBlock(), _createBlock(_component_the_footer, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}