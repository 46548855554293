import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    columns: _ctx.columns,
    "data-source": _ctx.data,
    onChange: _ctx.onChange
  }, null, 8, ["columns", "data-source", "onChange"]))
}